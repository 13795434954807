/* General styles */
body {
    background-color: #121212;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
    height: 100%;
}

html,
body,
#root {
    height: 100%;
}

/* Center the main app container */
.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative; /* Add this to position the corner buttons */
}

.bottom-left,
.bottom-right {
    position: absolute;
    bottom: 20px;
}

.bottom-left {
    left: 20px;
}

.bottom-right {
    right: 20px;
}

.corner-button {
    background-color: #000000;
    color: #00ff00;
    border: 2px solid #00ff00;
    padding: 10px 20px;
    text-decoration: none;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.corner-button:hover {
    background-color: #1e1e1e;
    color: #00ff00;
}

/* Result modal styles */
.result-modal {
    max-width: 400px;
    margin: 0 auto;
    background-color: #000000;
    color: #00ff00;
    border: 2px solid #00ff00;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
}

.result-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.result-item div {
    width: 100%;
}

/* To account for anyone trying to use this site on a mobile device */
@media only screen and (max-width: 600px) {
    .desktop-only {
        display: none;
    }
    .mobile-warning {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        color: #00ff00; /* Use the same color as the loading indicator */
        background-color: black;
        font-size: 1.2em;
        text-align: center;
        padding: 20px;
        flex-direction: column;
    }
    .back-button {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #00ff00; /* Use the same color as the text */
        color: black;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
    }
    .back-button:hover {
        background-color: #00cc00; /* Slightly darker shade on hover */
    }
}

@media only screen and (min-width: 601px) {
    .desktop-only {
        display: block;
        width: 100%;
    }

    .mobile-warning {
        display: none;
    }
}
