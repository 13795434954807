/* ResultModal.css */

.overlay {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s ease-in-out;
}

.modal {
    background: #1e1e1e;
    border: 2px solid #00ff00;
    border-radius: 10px;
    padding: 20px;
    width: 600px;
    text-align: center;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    animation: slideIn 0.5s ease-in-out;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2em;
}

.modal h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    text-shadow: 0 0 5px #00ff00;
}

.modal p {
    margin: 10px 0;
    text-shadow: 0 0 5px #00ff00;
}

.close-button {
    background-color: #00ff00;
    color: #1e1e1e;
    border: 2px solid #00ff00;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
    margin-top: 20px;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
}

.close-button:hover {
    background-color: #1e1e1e;
    color: #00ff00;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
