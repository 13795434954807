:root {
    --terminal-width: 1100px; /* Updated width to match the width in .terminal */
    --terminal-height: 500px; /* Updated height to match the height in .terminal */
    --terminal-bradius: 5px;
    --prompt: #ff1919;
    --top-bar: #2e3440; /* XFCE menu bar color */
    --top-bar-text: #d8dee9; /* XFCE menu bar text color */
    --shell: rgba(15, 15, 15, 0.8);
    --path: #5d8dbc;
}

ul {
    list-style: none;
    padding: 0;
}

ul li {
    display: inline;
    margin-right: 8px;
}

.terminal {
    position: relative;
    width: min-content;
    height: min-content;
    min-width: var(--terminal-width);
    min-height: var(--terminal-height);
    display: grid;
    grid-auto-rows: 0.1fr 1fr;
    overflow-y: auto;
    background-color: rgba(19, 19, 19, 0.753); /* Background color of the terminal */
    color: #ffffff; /* Text color */
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace; /* Monospace font */
    border-radius: var(--terminal-bradius);
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.terminal .top-bar {
    font-family: Cantarell, sans-serif;
    text-align: left;
    font-size: 15px;
    background-color: var(--top-bar);
    color: var(--top-bar-text);
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.terminal .top-bar .top-btn-left {
    display: flex;
}

.terminal .top-bar .menu-icon {
    cursor: pointer;
    font-size: 16px;
    color: var(--top-bar-text);
    transition: background-color 0.3s ease;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
}

.terminal .top-bar .menu-icon:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light grey background on hover */
}

.terminal .top-bar .top-btn-right {
    display: flex;
}

.terminal .top-bar .top-btn-right .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: relative;
    transition: background-color 0.3s ease;
}

.terminal .top-bar .top-btn-right .circle svg {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.terminal .top-bar .top-btn-right .circle.yellow {
    background-color: #f1fa8c;
}

.terminal .top-bar .top-btn-right .circle.green {
    background-color: #50fa7b;
}

.terminal .top-bar .top-btn-right .circle.red {
    background-color: #ff5555;
}

.terminal .top-bar .top-btn-right .circle.yellow:hover {
    background-color: #ffcc00;
}

.terminal .top-bar .top-btn-right .circle.green:hover {
    background-color: #00cc00;
}

.terminal .top-bar .top-btn-right .circle.red:hover {
    background-color: #cc0000;
}

.terminal .top-bar .top-btn-right .circle:hover svg {
    opacity: 1;
    color: #000000; /* Solid color for the icon on hover */
}

.terminal .top-bar .title-bar {
    overflow: hidden;
    height: 32px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: 0.5rem;
}

.terminal .top-bar .menu-bar {
    padding: 0 2px;
    margin: 3px;
}

.terminal .top-bar .menu-bar li {
    color: var(--top-bar-text);
    cursor: pointer;
}

.terminal .top-bar .menu-bar li:hover {
    background-color: #4c566a; /* Hover background color */
}

.terminal .top-bar .title-bar .title {
    text-align: center;
    padding-left: 45px;
    font-family: 'Courier New', Courier, monospace;
}

.terminal .top-bar .title-bar .top-btn {
    top: 0;
}

.terminal:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3c3c3c;
    opacity: 0.5;
    z-index: -1;
}

.terminal-line {
    display: flex;
    justify-content: center;
    font-family: monospace;
    font-size: 2.1ch;
    padding-top: 1em;
    padding-right: 5%;
    padding-left: 5%;
    white-space: pre-wrap;
    text-wrap: balance;
}

.terminal-user {
    display: flex;
    align-items: center;
    font-size: medium;
    flex: auto;
}

.terminal-prompt-input {
    flex: 1;
    display: flex;
    color: white;
    font-size: medium;
}

.terminal-prompt-input > input {
    flex: 1;
    width: 100%;
    background-color: transparent;
    caret-shape: bar;
    caret-color: white;
    color: white;
    outline: none;
    font-size: medium;
    white-space: pre;
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace; /* Monospace font */
}

.terminal-prompt-input span {
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace; /* Monospace font */
    font-size: medium;
    white-space: pre;
}

.terminal-link {
    @apply text-green-400 underline transition duration-300 ease-in-out hover:text-green-300 hover:no-underline;
    text-shadow: 0 0 5px #00ff00;
    font-family: 'Courier New', Courier, monospace;
}

.terminal-link:hover {
    @apply text-green-300;
    text-shadow: 0 0 10px #00ff00;
}

.terminal-history {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.debug-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
}

.control-element {
    height: 40px;
    font-size: 16px;
    border-radius: 4px;
    padding: 0 20px; /* Increased padding for better click area */
    border: 2px solid #00ff00;
    outline: none;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-align: center; /* Ensure text is centered */
}

#test-select {
    background-color: #000000;
    color: #00ff00;
    border: 2px solid #00ff00;
    height: 40px;
    font-size: 16px;
    border-radius: 4px;
    padding: 0 10px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none; /* Remove default arrow */
    cursor: pointer;
}

#test-select:disabled {
    background-color: #444444;
    color: #777777;
    cursor: not-allowed;
}

#test-select:hover {
    background-color: #222222;
}

#test-select option,
#test-select optgroup {
    background-color: #000000; /* Background color for options */
    color: #00ff00; /* Text color for options */
}

.start-test-button,
.stop-test-button {
    background-color: #000000;
    color: #00ff00;
    font-weight: bold;
    cursor: pointer;
}

.start-test-button:hover,
.stop-test-button:hover {
    background-color: #1e1e1e;
    color: #00ff00;
}

.start-test-button:disabled,
.stop-test-button:disabled {
    background-color: #444444;
    color: #777777;
    cursor: not-allowed;
}

@media (min-width: 640px) {
    /* sm breakpoint */
    .grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .sm\:flex-row {
        flex-direction: row;
    }
}
