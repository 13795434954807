/* TextInput.css */

.text-input-container {
    position: relative;
}

.text-input-display {
    display: flex;
    align-items: center;
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
}

.empty-input-line {
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
}

.terminal-cursor {
    display: inline-block;
    width: 0px;
    background-color: white;
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.correct-char {
    color: white;
}

.incorrect-char {
    color: red;
}

.bg-char {
    color: grey;
    opacity: 0.5;
}

#input-area {
    caret-color: white;
    background: none;
    height: min-content;
    border: none;
    outline: none;
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
    font-size: medium;
}

/* Correct space styles */
.correct-space {
    background-color: transparent; /* Hide the character itself */
}

/* Incorrect space styles */
.incorrect-space {
    position: relative;
    background-color: transparent;
    color: transparent;
}

.incorrect-space::before {
    content: '\u00A0';
    background-color: red;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.8;
    animation: flash 1s infinite;
}

@keyframes flash {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.feedback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    opacity: 1;
    animation: floatUp 1s ease-in-out forwards;
}

.feedback.correct {
    color: green;
}

.feedback.incorrect {
    color: red;
}

@keyframes floatUp {
    0% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
}
